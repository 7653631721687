import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showMsg } from "utils/general";
import { storeAddCustomerClient } from "features/company/clientActions";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import ConnectSecureClientsDataTableData from "./components/ConnectSecureClientsDataTableData";

import SaveConnectWiseApiKeysForm from "../../Connectwise/SaveConnectWiseApiKeys/components/SaveConnectWiseApiKeysForm";
import { Grid } from "@mui/material";
import ConnectSecureClientsDataTable from "./components/ConnectSecureClientsDataTable";

const ImportFromConnectWise = (props) => {
  const { objUser } = props;
  const [contacts, setContacts] = useState(null);
  const [remainingActiveClientsQuota, setRemainingActiveClientsQuota] =
    useState(0);
  const [numberOfUnitsAllowed, setNumberOfUnitsAllowed] = useState(0);
  const [objTableData, setObjTableData] = useState(
    ConnectSecureClientsDataTableData(objUser, () => importContacts())
  );
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [importError, setImportError] = useState(false);
  const [clientsToImport, setClientsToImport] = useState([]);
  // const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  // const query = new URLSearchParams(search);
  // const state = JSON.parse(query.get("state"));
  // const routeDictionary = state.routeDictionary;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  // const client_id = state.id;
  const client_id = id;
  // const tenant = query.get("tenant");
  useEffect(() => {
    importContacts();
  }, []);
  const importContacts = async () => {
    setImportError(false);
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/import-from-connectwise`,
        {
          id,
        }
      );

      const objRows = [];

      const objClientsList = res.data.clients_from_connectwise.map(
        (contact) => {
          contact.checked = false;
          return contact;
        }
      );

      setContacts(objClientsList);
      setRemainingActiveClientsQuota(res.data.remaining_active_clients_quota);
      setNumberOfUnitsAllowed(res.data.number_of_units_allowed);

      for (let index = 0; index < objClientsList.length; index++) {
        const element = objClientsList[index];
        const objTr = {};
        objTr.name = element.name;
        objTr.address_1 = element.address_1;
        objTr.address_2 = element.address_2;
        objTr.city = element.city;
        objTr.state = element.state;
        objTr.zip = element.zip;
        objTr.checked = element.checked;
        objTr.duplicate = element.duplicate;
        objRows.push(objTr);
      }

      setTotalCount(objClientsList.length);
      setObjTableData({
        ...objTableData,
        rows: objRows,
      });
    } catch (error) {
      console.log("error", error);
      showMsg("error", error.response.data.message);
      setImportError(true);
      // navigate(`/clients/${client_id}/contacts/create`, {
      //   state: { routeDictionary },
      // });
    }
  };
  const tryAgain = () => {
    window.location.reload();
  };

  const handleToggle = (i) => () => {
    if (contacts[i].duplicate) return;
    contacts[i].checked = !contacts[i].checked;
    setContacts([...contacts]);
  };
  const onImport = async () => {
    setIsLoading(true);
    // const contactsToImport = contacts.filter((contact) => contact.checked);
    if (clientsToImport.length === 0) {
      showMsg("info", "Please select at least one client to import.");
      setIsLoading(false);
      return;
    } else if (clientsToImport.length > remainingActiveClientsQuota) {
      showMsg(
        "info",
        `You can only add a maximum of ${numberOfUnitsAllowed} clients on your current plan. Your remaining quota is ${remainingActiveClientsQuota} clients. Please upgrade your plan.`
      );
      setIsLoading(false);
      return;
    } else {
      const storePromises = [];
      clientsToImport.forEach((objPostData) => {
        objPostData.id = client_id;
        storePromises.push(
          dispatch(storeAddCustomerClient({ objUser, objPostData }))
        );
      });
      try {
        const result = await Promise.all(storePromises);
        showMsg("success", "Clients imported successfully.");
        setIsLoading(false);
        navigate(`/clients`, {
          state: { routeDictionary },
        });
      } catch (error) {
        setIsLoading(false);
        console.log("error", error);
        showMsg("error", "Something went wrong, please try again.");
      }
    }
  };
  const onClose = () => {
    navigate(`/clients/add-new-client`, {
      state: { routeDictionary },
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title="Import clients from ConnectWise" />
      <MDBox>
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Import clients from ConnectWise
        </MDTypography>
        <Grid mt={3} container spacing={2}>
          {/* <Grid item xs={12} lg={5}>
            <Card>
              <MDBox>
                <SaveConnectWiseApiKeysForm />
              </MDBox>
            </Card>
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox
                display="flex"
                alignItems="center"
                flexDirection="column"
                mb={2}
                p={5}
              >
                {contacts === null ? (
                  <>
                    {importError === false ? (
                      <>
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                        <p style={{ textAlign: "center" }}>
                          Importing clients. Please wait...
                        </p>
                      </>
                    ) : (
                      <MDButton
                        type="button"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 12px" }}
                        onClick={tryAgain}
                      >
                        Try Again
                      </MDButton>
                    )}
                  </>
                ) : (
                  <>
                    <ConnectSecureClientsDataTable
                      table={objTableData}
                      entriesPerPage={true}
                      canSearch
                      totalCount={totalCount}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      _page={page}
                      setPage={setPage}
                      setObjTableData={setObjTableData}
                      objUser={objUser}
                      setClientsToImport={setClientsToImport}
                      clientsToImport={clientsToImport}
                    />
                    <MDBox
                      display="flex"
                      alignItems="end"
                      sx={{ width: "100%" }}
                      flexDirection="row-reverse"
                      gap={2}
                    >
                      {isLoading ? (
                        <LoadingSpinner
                          subClass="text-center"
                          color="white"
                          size="lg"
                        />
                      ) : (
                        <MDButton
                          type="button"
                          // variant="gradient"
                          color="success"
                          sx={{ padding: "12px 12px" }}
                          onClick={onImport}
                        >
                          Import
                        </MDButton>
                      )}
                      <MDButton
                        type="button"
                        variant="outlined"
                        color="dark"
                        sx={{ padding: "12px 12px" }}
                        onClick={onClose}
                      >
                        Back
                      </MDButton>
                    </MDBox>
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};
export default ImportFromConnectWise;
